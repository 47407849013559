<script>
import axios from "axios";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import router from '../../../router/index';
import { authHeader } from "../../../helpers/auth-header";

import ConfirmationModal from "@/components/modals/confirmation-modal";
import MessageModal from "@/components/modals/message-modal";

import ListingTable from "@/components/listings/listing-table";

import InputGroup from "@/components/forms/input-group";
import SelectGroup from "@/components/forms/select-group";
import MultiSelectGroup from "@/components/forms/multiselect-group_new";

export default {
    components: {
        Layout,
        PageHeader,
        ConfirmationModal,
        MessageModal,
        ListingTable,
        InputGroup,
        SelectGroup,
        MultiSelectGroup,
    },
    data() {
        return {
            title: "Admin",
            items: [
                {
                    text: "Admin"
                },
            ],
            filter: {
                name: '',
                email: '',
                status: 2,
                selected_role: '',
            },
            statusOptions: [
                { name: 'All', value: 2 },
                { name: 'Active', value: 1 },
                { name: 'Inactive', value: 0 },
            ],
            roleOptions: [
                { name: 'SuperAdmin', value: 1 },
                { name: 'Management', value: 2 },
            ],
            fields: [
                // { key: 'checkbox', sortable: false, label: '' },
                { key: 'id', sortable: true, label: 'ID' },
                { key: "name", sortable: true, label: "Name" },
                { key: "email", sortable: true, label: 'Email' },
                { key: "status", sortable: true, label: "Status" },
                { key: "action" },
            ],
        };
    },
    computed: {

    },
    watch: {

    },
    async mounted() {
        // Set the initial number of items
        // await this.retrieveRecords();
    },
    methods: {
        goToEditPage(id) {
            router.push({name: 'Admin Form', params: {id}});
        },

        goToEditPassword(id) {
            router.push({name: 'Admin Edit Password', params: {id}});
        },

        deleteRecord(id) {
            const deleteCallback = async () => {
                await axios.delete(`${process.env.VUE_APP_APIURL}/admin/${id}`, {
                    headers: authHeader(),
                });
                this.filterTable();
                this.$refs.confirmationModal.closeModal();
                this.$refs.messageModal.showModal('The record has been deleted');
            }
            this.$refs.confirmationModal.showModal(`Confirm delete admin (ID: ${id})?`, deleteCallback);
        },

        setAdminView(filter) {
            this.filter = Object.assign(this.filter, filter);
        },

        initialSetting() {
            if (typeof(this.filter.selected_role)  === 'string') {
                this.filter.selected_role = this.filter.selected_role.split(',');
            }
        },

        filterTable() {
            const filter = JSON.parse(JSON.stringify(this.filter));
            if (filter.selected_role !== null) {
                const roles = [];
                for (const role of filter.selected_role) {
                    roles.push(role);
                }
                filter.selected_role = roles.join(',');
            }

            this.$refs.listingTable.filterTable(filter);
        },

        resetTable() {
			this.filter = {
				name: '',
                email: '',
                status: 2,
                selected_role: '',
			};
			const filter = this.filter;
			this.$refs.listingTable.filterTable(filter);
		},
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <ConfirmationModal ref="confirmationModal" />
        <MessageModal ref="messageModal" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card" style="border-radius: 15px;">
                    <div class="card-body pt-0">
                        <div class="row mt-2">
                            <div class="col-sm-12 col-md-4 col-lg-3 mt-2">
                                <InputGroup type="text" id="name" displayName="Name" v-model="filter.name" :onEnter="filterTable" />
                            </div>
                            <div class="col-sm-12 col-md-4 col-lg-3 mt-2">
                                <InputGroup type="email" id="email" displayName="Email" v-model="filter.email" :onEnter="filterTable" />
                            </div>
                            <div class="col-sm-12 col-md-4 col-lg-3 mt-2">
                                <SelectGroup id="status" displayName="Status" v-model="filter.status" :options="statusOptions" />
                            </div>
                            <div class="col-sm-12 col-md-4 col-lg-3 mt-2">
                                <MultiSelectGroup id="roles" displayName="Roles" v-model="filter.selected_role" :options="roleOptions" />
                            </div>
                            <div class="col-12 d-flex justify-content-end" style="margin-top: 12px;">
								<a href="javascript:void(0);" class="btn btn-filter" @click="filterTable">
									Filter
								</a>
								<a href="javascript:void(0);" class="btn btn-orange" style="margin-left: 11px;" @click="resetTable">
									Reset
								</a>
							</div>
                        </div>
                        <ListingTable ref="listingTable" :fields="fields" :setAdminView="setAdminView" :initialSetting="initialSetting" path="admin" listName="admins">
                            <template v-slot:cell(status)="row">
                                <div class="badge font-size-12" :class="{
                                    'badge-danger': parseInt(`${row.value}`) === 0,
                                    'badge-success': parseInt(`${row.value}`) === 1,
                                }">{{ parseInt(row.value) === 1 ? 'Active' : 'Inactive' }}</div>
                            </template>
                            <template v-slot:cell(action)="{ item }">
                                <router-link class="mr-3 grey-icon-color" :to="{name: 'Admin Form', params: {id: item.id}}"><i class="ri-pencil-line font-size-18" v-b-tooltip.hover title="Edit"></i></router-link>
                                <router-link class="mr-3 grey-icon-color" :to="{name: 'Admin Edit Password', params: {id: item.id}}"><i class="ri-lock-line font-size-18" v-b-tooltip.hover title="Edit Password"></i></router-link>
                                <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover title="Delete"
                                    @click="deleteRecord(item.id)">
                                    <i class="ri-delete-bin-line font-size-18"></i>
                                </a>
                            </template>
                        </ListingTable>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>