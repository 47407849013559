<script>
import Multiselect from "vue-multiselect";

export default {
    components: {
        Multiselect,
    },
    props: {
        index: {
            default: -1,
        },
        id: {
            required: true,
            type: String,
        },
        displayName: {
            required: true,
            type: String,
        },
        value: {

        },
        options: {
            required: true,
            default() {
                return [];
            }
        },
        error: [],

        hideLabel: {
            type: Boolean,
        },
        onInitial: {

        },
    },

    data() {
        return {
            oldSelected: null,
            selected: null,
        };
    },

    created() {

    },

    mounted() {
        this.select(this.value);
        if (this.onInitial) {
            this.onInitial(this.index);
        }
    },

    watch: {
        selected(newValue) {
            if (newValue === null) {
                this.$emit('input', null);
            } else {
                let selectVal = [];
                newValue.forEach(element => {
                    selectVal.push(element.value);
                });
                this.$emit('input', selectVal);
            }
        }
        // value(newValue) {
        //     this.select(newValue);
        // },

        // options(newValue) {
        //     if (newValue && newValue !== null) {
        //         this.select(this.value);
        //     }
        // },

        // selected(newValue) {
            
        //     if (newValue === null) {
        //         if (this.value !== null) {
        //             this.handleInput(null);
        //             return;
        //         }
        //     }

        //     if (this.value === null) {
        //         this.handleInput(null);
        //         return;
        //     }

        //     if (!this.allMatched(newValue, this.value)) {
        //         this.handleInput(newValue);
        //     }
        // },
    },

    methods: {
        test(val) {
            // this.$emit('test')
            console.log(val);
        },

        select(newValue) {
            if (newValue === null) {
                this.selected = null;
                return;
            }

            if (this.selected === null) {
                this.selectItem(newValue);
                return;
            }

            if (!this.allMatched(this.selected, newValue)) {
                this.selectItem(newValue);
            }
        },

        selectItem(newValue) {
            if (newValue === null) {
                this.selected = null;
                return;
            }

            const selections = [];
            for (const value of newValue) {
                selections.push(this.getSelectionItem(this.options, value))
            }
            
            this.selected = selections;
        },

        allMatched(selected, values) {
            if (selected === null) {
                return this.values === null;
            }

            let matched = true;
            for (const selectedVal of selected) {
                if (!values.includes(selectedVal.value)) {
                    matched = false;
                    break;
                }

            }
            console.log(values.length);
            console.log(selected.length);

            return matched;
        },

        handleInput(values) {
            if (values === null) {
                this.$emit('input', null);
            } else {
                // console.log(values);
                const selections = [];
                for (const value of values) {
                    selections.push(value.value);
                }

                this.$emit('input', selections);
            }
        },
    }
};
</script>

<template>
    <div class="form-group">
        <div>
            <label :for="id" v-if="hideLabel === false" class="mb-0">{{ displayName }}</label>
            <multiselect class="pl-0 pr-0" v-model="selected" :options="options" :multiple="true"
                :placeholder="`Select ${displayName}`" :searchable="true" label="name" track-by="value">
            </multiselect>
        </div>
        <div class="alert alert-danger mt-3" role="alert" v-if="error && error.length > 0">
            {{ error[0] }}
        </div>
    </div>
</template>